#file-input {
  display: none;
}

/* date time picker css */
input[type="datetime-local"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.trophy-icon {
  height: 100%;
  width: 100%;
}

.course-card:hover > .course-card-img {
  filter: brightness(0.95);
}

*{
  scroll-behavior: smooth;
}
